import { AxiosInstance } from 'axios';
import PubSub from 'pubsub-js';

import { setUpHttpClientConfig, httpClient } from '@rainhq/http-client';
import { getCookie } from '@rainhq/dom-storage';

const apiSecret = process.env.NEXT_PUBLIC_API_SECRET || '';
const apiKey = process.env.NEXT_PUBLIC_API_KEY || '';
const baseUrl = process.env.NEXT_PUBLIC_API_URL || '';

export function initHttpClient(): AxiosInstance {
  const lang = getCookie('NEXT_LOCALE') || 'en';

  setUpHttpClientConfig({
    lang,
    baseUrl,
    urlsWithoutJWT: ['/prices/history', '/currencies', '/token'],
    apiSecret,
    apiKey,
    eventBus: PubSub,
  });

  return httpClient;
}
