import { defaultStackParser, defaultIntegrations } from '@sentry/nextjs';

// used in config files at repo root (not in scope for linting)
// ts-unused-exports:disable-next-line
export const sentryConfig = {
  dsn: process.env.SENTRY_DSN,
  tracesSampleRate: 0.5,
  enabled: process.env.SENTRY_DSN !== '',
  environment: process.env.NODE_ENV,
  stackParser: defaultStackParser,
  ignoreErrors: ['Script error.', 'Network Error'],
  integrations: [...defaultIntegrations],
};
