import { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import { appWithTranslation, useTranslation } from 'next-i18next';

import { changeJWT } from '@rainhq/http-client';
import { ThemeProvider } from '@rainhq/ui-library';

import { initHttpClient } from 'config/httpClient';
import { ApplicationContext } from 'contexts/application';
import { IApplicationContextData, IApplicationContext } from 'types/application';

import { Language } from 'enums/language';

function App({ Component, pageProps, err }: AppProps & { err?: Error }) {
  const [context, setContext] = useState<IApplicationContextData>({
    jwt: undefined,
    user: undefined,
    loaded: false,
    jurisdiction: undefined,
  });

  const { i18n } = useTranslation('common');
  const dir = (i18n.language as Language) === Language.Arabic ? 'rtl' : 'ltr';
  const initContext = { ...context, updateContext: setContext } as IApplicationContext;

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.dir = dir;
  }, [dir, i18n.language]);

  useEffect(() => {
    initHttpClient();
    changeJWT(context.jwt || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.user]);

  return (
    <ApplicationContext.Provider value={initContext}>
      <ThemeProvider themeStyle="light" dir={dir} prefix="support" globalStyles>
        <Component {...pageProps} err={err} />
      </ThemeProvider>
    </ApplicationContext.Provider>
  );
}

export default appWithTranslation(App);
